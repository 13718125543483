<template>
  <!-- menu -->
  <div
    v-if="toggleMenu"
    class="
      fixed
      overflow-x-hidden
      overscroll-y-auto
      inset-0
      flex
      justify-center
      items-center
      z-40
    "
  >
    <div class="relative mx-auto w-auto max-w-2xl">
      <div class="bg-white w-full p-6 rounded-xl shadow-2xl">
        <div class="mt-4">
          <div class="flex flex-col mb-8">
            <a href="/" class="btn btn-outline-primary px-8 py-2 mb-2 sm:mr-2"
              >Inicio</a
            >
            <a
              href="/consejos"
              class="btn btn-outline-primary px-8 py-2 mb-2 sm:mr-2"
              >Consejos</a
            >
            <!-- <a
              href="https://forms.gle/pX6fpDiuoPGh3A9M6"
              target="_blank"
              class="btn btn-primary px-8 py-2 mb-2 sm:mr-2"
              >Hacer Reclamo</a
            > -->
            <a
              href="https://wa.me/5492616502203"
              class="btn btn-outline-primary px-8 py-2"
              >Solicitar Asesoramiento</a
            >
          </div>
          <div class="text-center">
            <button
              class="btn btn-outline-primary p-2"
              @click="toggleMenu = false"
            >
              <svg
                class="h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-if="toggleMenu" class="fixed z-30 inset-0 opacity-25 bg-black"></div>

  <header class="w-full bg-white py-12 sticky top-0 z-20">
    <nav class="container flex flex-wrap items-center justify-between px-8">
      <!-- logo -->
      <div>
        <router-link to="/">
          <img
            src="../assets/img/clicSocial-logo.svg"
            alt="Clic Social"
            class="w-48"
          />
        </router-link>
      </div>

      <!-- burger -->
      <div class="block xl:hidden">
        <button
          class="btn btn-outline-primary p-2"
          @click="toggleMenu = !toggleMenu"
        >
          <svg
            class="h-6 w-6"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M4 6h16M4 12h16m-7 6h7"
            />
          </svg>
        </button>
      </div>

      <!-- navigation -->
      <div class="hidden xl:block xl:flex xl:items-center">
        <div>
          <router-link class="mr-4 font-bold text-gray-600" to="/"
            >Inicio</router-link
          >
          <router-link class="font-bold text-gray-600" to="/consejos"
            >Consejos</router-link
          >
        </div>
        <div class="flex flex-wrap lg:ml-4">
          <!-- <a
            href="https://forms.gle/pX6fpDiuoPGh3A9M6"
            target="_blank"
            class="btn btn-primary px-8 py-2 ml-2"
            >Hacer Reclamo</a
          > -->
          <!-- <a
            href="https://wa.me/5492616502203"
            class="btn btn-outline-primary px-8 py-2 ml-2"
            >Solicitar Asesoramiento</a
          > -->
          
          <a href="tel:+5492616502203" class="btn btn-outline-primary p-2 ml-2">
            <svg
              class="h-6 w-6"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
              />
            </svg>
          </a>
        </div>
      </div>
    </nav>
  </header>
</template>

<script>
export default {
  data() {
    return {
      toggleMenu: false,
    };
  },
};
</script>
